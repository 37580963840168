import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class HttpService {

  constructor(private http: HttpClient,
    private router: Router,
    private authService: AuthService) { }

  doGet(url: string, token: string): Observable<any> {
    console.log('Do Get:' + url);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    const options = { headers: headers, withCredentials: false };
    // return this.http.get(url, options);
    return this.http.get(url, options).pipe(
      map((response: Response) =>
        this.extractData(response)
      ),
      catchError(this.handleError<any>('doGet'))
    );
  }

  doPost(url: string, payload: any, token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    const options = { headers: headers, withCredentials: true };
    return this.http.post(url, payload, options).pipe(
      map((response: Response) => this.extractData(response)),
      catchError(this.handleError<any>('doPost'))
    );
  }

  private extractData(res: Response) {
    let result = res;
    return result || {};
  }

  doPut(url: string, payload: any, token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    const options = { headers: headers, withCredentials: false };
    return this.http.put(url, payload, options).pipe(
      map((response: Response) => this.extractData(response)),
      catchError(this.handleError<any>('doPut'))
    );
  }

  doDelete(url: string, token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    const options = { headers: headers, withCredentials: false };
    return this.http.delete(url, options).pipe(
      map((response: Response) => this.extractData(response)),
      catchError(this.handleError<any>('doDelete'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status == 401) {
        this.authService.clearToken()
        this.authService.clearApiUrl();
        this.router.navigate(['/']);
        window.location.reload();
      } else {
        console.log('Error status:' + error.status);
        console.log(error.status); // log to console instead
        console.log(`${operation} failed: ${error.message}`);
        throw new Error(error.message)
      }
      return;
    };
  }
}
