<nav class="navbar fixed-top navbar-expand-lg headerBar"></nav>

<div class="body-container">
  <div class="under-nav">
      <div class="container-verify">
          <div class="title">
              <h3>Email verification</h3>
          </div>
          <div *ngIf="result" class="alert verify-success mt-4">
              <span>Verification success</span>
          </div>
          <div *ngIf="!result" class="alert verify-failed mt-4">
              <span>Verification failed</span>
          </div>
          <div *ngIf="!result" class="description">
              <span>Something went wrong.</span>
          </div>
          <div *ngIf="result" class="description">
              <span>The email has been correctly verified.</span>
          </div>
      </div>
  </div>
</div>